// sort by date
export const sortArrayInDescendingOrder = (arr) => {
  return arr.sort((a, b) => {
    return b.date - a.date;
  });
};
export const sortObjectInDescendingOrder = (obj) => {
  return Object.entries(obj).sort((a, b) => b[1] - a[1]);
};

export const factorialize = (num) => {
  if (num < 0) return -1;
  else if (num === 0) return 1;
  else {
    return num * factorialize(num - 1);
  }
};
export const formatNumber = (num) => {
  let formatedNumber = +num < 10 ? `0${+num}` : `${+num}`;
  return formatedNumber;
};

export const createRedNumberDictionary = (resultArray) => {
  let redNumberFrequencyDictionary = {};
  for (let arr of resultArray) {
    arr.forEach((item) => {
      if (!(item.winning_number[5] in redNumberFrequencyDictionary)) {
        redNumberFrequencyDictionary[item.winning_number[5]] = 0;
      } else {
        redNumberFrequencyDictionary[item.winning_number[5]] += 1;
      }
    });
  }
  return redNumberFrequencyDictionary;
};

export const groupResult = (arr) => {
  let allresultsGrouped = [];
  let results = [];
  for (let i = 0; i < arr.length; i++) {
    results.push(arr[i]);
    if (i > 0 && (i + 1) % 10 === 0) {
      allresultsGrouped.push(results);
      results = [];
    } else if (i + 1 === arr.length && (i + 1) % 10 !== 0) {
      allresultsGrouped.push(results);
      results = [];
    }
  }
  return allresultsGrouped;
};

export const formatResults = (pastResults, drawingDay) => {
  let results = [...pastResults];

  let arrayBasedOnDay = [];
  let allresults = results.map((number) => {
    let date = new Date(number.date).toLocaleDateString();
    let winNumArr = number.winning_number.split(" ");
    let fullDate = date;
    if (drawingDay === "All") {
      return { date: fullDate, winning_number: winNumArr };
    } else if (fullDate.includes(drawingDay)) {
      arrayBasedOnDay.push({ date: fullDate, winning_number: winNumArr });
      return { date: fullDate, winning_number: winNumArr };
    }
    return null;
  });
  let allresultsGrouped = [];
  results = [];

  // group the results
  if (arrayBasedOnDay.length === 0) {
    allresultsGrouped = groupResult(allresults);
  } else {
    allresultsGrouped = groupResult(arrayBasedOnDay);
  }
  return [allresults, allresultsGrouped];
};
