import React from "react";
import "./index.scss";
import profile from "../../images/profile/portrait.png";
import Skills from "../Skills";

const Intro = () => {
  return (
    <div id="intro" className="container-fluid pb-2 pt-2">
      <div>
        <div className="profile-ctn">
          <img src={profile} alt="" />
        </div>
        <div className="summary">
          <h3 className="name">Bertrand S. Muhonga</h3>
          <h4 className="role">Software Engineer</h4>
          <h5 className="location">United States</h5>

          <div className="social">
            <a
              href="https://www.linkedin.com/in/b-muhonga/"
              target="_blank"
              rel="noreferrer"
            >
              <span className="bi bi-linkedin social-icon"></span>
            </a>
            <a
              href="https://github.com/BertrandMuh"
              target="_blank"
              rel="noreferrer"
            >
              <span className="bi bi-github social-icon"></span>
            </a>
            <a
              href="https://www.youtube.com/channel/UCNj2DBBWdpzzjmp7KUBzJkw"
              target="_blank"
              rel="noreferrer"
            >
              <span className="bi bi-youtube social-icon"></span>
            </a>

            <a href="#contact">
              <span className="bi bi-envelope social-icon"></span>
            </a>
          </div>
          <div className="skills mb-2 mt-2">
            <Skills />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Intro;
