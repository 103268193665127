export const calculate_taxes = (salary) => {
  const socialSecurityTaxRate = 0.062;
  const medicareReguralTaxRate = 0.0145;
  const medicareSurplusTaxRate = 0.0235;

  let federalTax = 0;
  const tax_10 = 11600 * 0.1;
  const tax_12 = tax_10 + (47150 - 11601) * 0.12;
  const tax_22 = tax_12 + (100525 - 47151) * 0.22;
  const tax_24 = tax_22 + (191950 - 100526) * 0.24;
  const tax_32 = tax_24 + (243725 - 191951) * 0.32;
  const tax_35 = tax_32 + (609350 - 243726) * 0.35;

  const ssTax =
    salary <= 168600
      ? salary * socialSecurityTaxRate
      : 168600 * socialSecurityTaxRate;

  const medicareTax =
    salary <= 200000
      ? salary * medicareReguralTaxRate
      : 200000 * medicareReguralTaxRate +
        (salary - 200000) * medicareSurplusTaxRate;
  // 10
  if (salary <= 11600) {
    federalTax = salary * 0.1;
  }
  // 12
  else if (salary > 11600 && salary <= 47150) {
    federalTax = tax_10 + (salary - 11600) * 0.12;
  }
  // 22
  else if (salary > 47150 && salary <= 100525) {
    federalTax = tax_12 + (salary - 47150) * 0.22;
  }
  // 24
  else if (salary > 100525 && salary <= 191950) {
    federalTax = tax_22 + (salary - 100525) * 0.24;
  }
  // 32
  else if (salary > 191950 && salary <= 243725) {
    federalTax = tax_24 + (salary - 191950) * 0.32;
  }
  // 35
  else if (salary > 243725 && salary <= 609350) {
    federalTax = tax_32 + (salary - 243725) * 0.35;
  }
  // 37
  else {
    federalTax = tax_35 + (salary - 609350) * 0.37;
  }

  return {
    ssTax: ssTax,
    medicareTax: medicareTax,
    federaltax: federalTax,
    totalTax: ssTax + medicareTax + federalTax,
  };
};

export const updateNumber = (setMoney, target, button) => {
  let currentValue = 0;
  const targetValue = target;
  const duration = 3000;
  const interval = duration * 0.01; // Update every 50 milliseconds

  const timer = setInterval(() => {
    setMoney(currentValue.toFixed(2)); // Update the displayed value
    currentValue += targetValue * 0.05;

    if (currentValue >= targetValue) {
      setMoney(targetValue.toFixed(2)); // Ensure the target value is reached exactly
      clearInterval(timer); // Stop the interval when the target is reached
      button.disabled = false;
    }
  }, interval);
};
