import React from "react";
import {
  FaDatabase,
  FaGitAlt,
  FaJava,
  FaJs,
  FaPython,
  FaReact,
} from "react-icons/fa";

const Skills = () => {
  return (
    <>
      <span>
        <FaReact />
        <span>React</span>
      </span>
      <span>
        <FaJs />
        <span>JavaScript</span>
      </span>
      <span>
        <FaGitAlt />
        <span>Git</span>
      </span>
      <span>
        <FaJava />
        <span>Java</span>
      </span>
      <span>
        <FaPython />
        <span>Python</span>
      </span>
      <span>
        <FaDatabase />
        <span>Database</span>
      </span>
    </>
  );
};

export default Skills;
