import React, { useContext } from "react";
import { AppContext } from "../../context/app_context";
import { formatResults } from "../../functions/lotto-useful-functions";
// import WinningNumber from '../Winning_number'
import "./index.scss";
import Frequency from "../numbers_frequency";

const PastResult = () => {
  const { pastResults, drawingDay, resultPage, setResultPage } =
    useContext(AppContext);

  //
  const returnWinningNumberJSX = (number) => {
    let winNumArrJSX = number.map((num, numIdx) => {
      return numIdx !== 5 ? (
        <span key={numIdx} className="white-ball">
          {num}
        </span>
      ) : (
        <span key={numIdx} className="red-ball">
          {num}
        </span>
      );
    });
    return winNumArrJSX;
  };

  let allresults = formatResults(pastResults, drawingDay)[1];
  let winningNumberJSX =
    allresults.length > 0
      ? allresults[resultPage].map((number, idx) => {
          return (
            <div key={idx} className="text-start">
              <p className="date">{number.date}</p>
              <p className="powerball">
                {returnWinningNumberJSX(number.winning_number)}
              </p>
            </div>
          );
        })
      : "";

  return (
    <div className="d-flex gap-5 flex-wrap justify-content-center">
      {allresults.length !== 0 ? (
        <>
          <div className="past-result-container">
            <div className="header">
              <p className="text-center w-100">Powerball Past Results</p>
            </div>
            {allresults.length > 0 ? (
              <div className="past-result">{winningNumberJSX}</div>
            ) : (
              ""
            )}
            <div className="page-button">
              <button
                type="button"
                className="fa fa-arrow-left"
                disabled={resultPage === 0 ? true : false}
                onClick={(e) =>
                  setResultPage(
                    e.target.className.includes("left")
                      ? resultPage - 1
                      : resultPage + 1
                  )
                }
              ></button>

              <span className="page-number">{resultPage + 1}</span>

              <button
                type="button"
                className="fa fa-arrow-right"
                disabled={resultPage === allresults.length - 1 ? true : false}
                onClick={(e) =>
                  setResultPage(
                    e.target.className.includes("left")
                      ? resultPage - 1
                      : resultPage + 1
                  )
                }
              ></button>
            </div>
          </div>
          <Frequency />
        </>
      ) : null}
    </div>
  );
};

export default PastResult;
