import React from "react";
import "./index.css";

const Welcome = () => {
  return (
    <div className="welcome  text-start  mt-3 ">
      <h1>
        <strong>
          Elevate Your Lottery Game: Introducing the Ultimate Combination
          Generator
        </strong>
      </h1>
      <p>
        Welcome to an innovative online platform that has redefined the art of
        generating lottery combinations. We proudly present a cutting-edge
        solution that brings efficiency, customization, and insight to the
        forefront of your lottery experience.
      </p>
      <br />
      <p>
        <strong>Tailored to You:</strong> Personalization takes center stage
        with our service. Tailor your combinations to your unique preferences by
        defining specific parameters and constraints. It's more than just random
        numbers – it's about crafting combinations that resonate with your
        strategy.
      </p>
    </div>
  );
};

export default Welcome;
