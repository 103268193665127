import React, { useState } from "react";
import "./index.scss";
import { calculate_taxes, updateNumber } from "../../functions/tax_calculation";

const TaxCalculator = (props) => {
  const {
    setSalary,
    setSsTax,
    setMonthlySsTax,
    setWeeklySsTax,
    setDailySsTax,
    setMedicareTax,
    setMonthlyMedicareTax,
    setWeeklyMedicareTax,
    setDailyMedicareTax,
    setFedIncomeTax,
    setMonthlyFedIncomeTax,
    setWeeklyFedIncomeTax,
    setDailyFedIncomeTax,
    setFedTaxRate,
    setMonthlySalary,
    setAfterTax,
    setWeeklySalary,
    setDailySalary,
    setMedicareRate,
    setSsRate,
    setMoney,
  } = props;

  const [disabled, setDisabled] = useState(false);
  const [toggle_hourly_cnt, setToggle_hourly_ctn] = useState(true);

  const enableButton = (e) => {
    const element = e.target;
    if (element.value === "" || +element.value <= 0) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  };

  const displayHourlyContainer = (e) => {
    const element = e.target;
    if (element.value === "hourly") {
      setToggle_hourly_ctn(false);
    } else {
      setToggle_hourly_ctn(true);
    }
    setDisabled(false);
  };

  const calculate = () => {
    const button = document.querySelector(".calculate");
    button.disabled = true;
    let salary = 0;

    if (toggle_hourly_cnt) {
      const annual_salary_input = +document.getElementById(
        "annual_salary_input"
      ).value;
      salary = annual_salary_input;
    } else {
      const weekly_hour = +document.getElementById("week_hour_input").value;
      const daily_rate = +document.getElementById("hour_salary_input").value;
      const overtime = weekly_hour > 40 ? weekly_hour % 40 : 0;
      salary =
        (daily_rate * (weekly_hour - overtime) + daily_rate * 1.5 * overtime) *
        52;
    }

    const taxes = calculate_taxes(salary);

    const salary_after_tax = salary - taxes.totalTax;

    const fedRate = (taxes.federaltax / salary) * 100;

    setSalary(salary.toFixed(2));

    // federal
    setFedTaxRate(fedRate.toFixed(2));
    setFedIncomeTax(taxes.federaltax.toFixed(2));
    setMonthlyFedIncomeTax((taxes.federaltax / 12).toFixed(2));
    setWeeklyFedIncomeTax((taxes.federaltax / 52).toFixed(2));
    setDailyFedIncomeTax(((taxes.federaltax * 8) / 2080).toFixed(2));

    // medicare
    setMedicareRate(taxes.medicareTax / salary);
    setMedicareTax(taxes.medicareTax.toFixed(2));
    setMonthlyMedicareTax((taxes.medicareTax / 12).toFixed(2));
    setWeeklyMedicareTax((taxes.medicareTax / 52).toFixed(2));
    setDailyMedicareTax(((taxes.medicareTax * 8) / 2080).toFixed(2));

    // social security
    setSsRate(taxes.ssTax / salary);
    setSsTax(taxes.ssTax.toFixed(2));
    setMonthlySsTax((taxes.ssTax / 12).toFixed(2));
    setWeeklySsTax((taxes.ssTax / 52).toFixed(2));
    setDailySsTax(((taxes.ssTax * 8) / 2080).toFixed(2));

    // money after tax
    setAfterTax(salary_after_tax.toFixed(2));
    setMonthlySalary((salary_after_tax / 12).toFixed(2));
    setWeeklySalary((salary_after_tax / 52).toFixed(2));
    setDailySalary(((salary_after_tax * 8) / 2080).toFixed(2));

    updateNumber(setMoney, salary_after_tax, button);
  };

  return (
    <div className="calculator container-fluid">
      <h2 className="calculator_header">Tax Calculator</h2>
      <div className="form position-relative d-flex flex-column center gap-2">
        <div id="salary_type_div">
          <div>
            <input
              type="radio"
              id="hourly"
              value="hourly"
              name="hour_annual"
              onClick={displayHourlyContainer}
            />
            <label htmlFor="hourly" className="salary_type">
              hourly
            </label>
          </div>
          <div>
            <input
              type="radio"
              id="annually"
              value="annually"
              name="hour_annual"
              defaultChecked
              onClick={displayHourlyContainer}
            />
            <label
              htmlFor="annually"
              className="salary_type"
              name="hour_annual"
            >
              annually
            </label>
          </div>
        </div>
        {toggle_hourly_cnt ? (
          <div className="salary_div">
            <label htmlFor="annual_salary_input">Anuual salary:</label>
            <input
              type="number"
              min="0"
              id="annual_salary_input"
              step={0.01}
              placeholder="eg. 55000"
              defaultValue={16640}
              onChange={enableButton}
            />
          </div>
        ) : (
          <div>
            <div className="salary_div">
              <label htmlFor="hour_salary_input">Wage per hour:</label>
              <input
                type="number"
                step="0.01"
                min="0"
                id="hour_salary_input"
                defaultValue={8}
                onChange={enableButton}
              />
            </div>
            <div className="salary_div">
              <label htmlFor="week_hour_input">Weekly hours:</label>
              <input
                type="number"
                step="0.01"
                min="0"
                id="week_hour_input"
                defaultValue={40}
                onChange={enableButton}
              />
            </div>
          </div>
        )}

        <button
          className="mt-2 btn btn-primary calculate"
          onClick={calculate}
          disabled={disabled}
        >
          Calculate
        </button>
      </div>
    </div>
  );
};

export default TaxCalculator;
