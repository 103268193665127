// import logo from './logo.svg';
import "./App.css";
import Contact from "./components/Contacts";
import Footer from "./components/Footer";
import Nav from "./components/Nav";
import Home from "./pages/home";
import { Routes, Route } from "react-router-dom";
import Lotto from "./pages/lotto-page";
import { useContext, useEffect } from "react";
import { AppContext } from "./context/app_context";
import { getData } from "./components/api_request";
import { sortArrayInDescendingOrder } from "./functions/lotto-useful-functions";
import Income from "./pages/income";

function App() {
  const { setPastResults } = useContext(AppContext);

  useEffect(() => {
    const apiCall = async () => {
      let arr = [];
      let response = await getData();
      response.forEach((element) => {
        arr.push({ date: new Date(element[8]), winning_number: element[9] });
      });
      let sortedArr = sortArrayInDescendingOrder(arr);
      setPastResults(sortedArr);
    };

    apiCall();
  }, [setPastResults]);

  return (
    <div className="App">
      <Nav />
      <div id="content">
        <Routes>
          <Route path="/lucky-lotto" element={<Lotto />} />
          <Route path="/income" element={<Income />} />
          <Route path="/" element={<Home />} />
        </Routes>
      </div>

      <Contact />
      <Footer />
    </div>
  );
}

export default App;
