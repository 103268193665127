import React, { useEffect } from "react";

import Education from "../../components/Education";
import Project from "../../components/Projects";
import About from "../../components/About";
import Intro from "../../components/Introduction";
import "./index.css";
import Experiences from "../../components/Experiences";

const Home = () => {
  useEffect(() => {
    // Scroll to the top of the page
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="homepage" id="home">
      <Intro />
      <About />
      <Experiences />
      <Project />
      <Education />
    </div>
  );
};

export default Home;
