import React from "react";

const TaxInfo = () => {
  return (
    <div>
      <h1 className="fs-1 text-center">Income Tax Calculator</h1>
      <p>
        <span className="fs-3">I</span>ncome tax is a government-imposed levy on
        an individual's or entity's earnings, serving as a primary revenue
        source for public services. It encompasses various income sources,
        including wages, profits, and dividends. Tax rates are generally
        progressive, with higher incomes incurring higher rates. Taxpayers can
        leverage deductions and credits to reduce their overall tax liability.
        Annual filing is mandatory, and employers often withhold taxes from
        paychecks. Different types of income may be subject to varied tax rates
        (e.g., capital gains, corporate profits). Government agencies, such as
        the Internal Revenue Service (IRS), oversee tax administration.
        Strategic financial decisions and selecting a filing status impact tax
        liability. Understanding jurisdiction-specific tax laws or seeking
        professional advice ensures compliance and strategic financial planning.
      </p>
      <h2 className="mt-5">Social Security Tax</h2>
      <p>
        Social Security tax is a payroll tax in the U.S. that funds the Old-Age,
        Survivors, and Disability Insurance (OASDI) program. Both employees and
        employers contribute 6.2% of the employee's wages, totaling 12.4%. There
        is a cap on taxable earnings, and the funds support retirement benefits,
        survivor benefits for family members, and disability benefits. The
        Social Security Administration administers the program, providing
        financial security to retirees, survivors, and disabled individuals.
      </p>
      <h2 className="mt-5">Medicare Tax</h2>
      <p>
        Medicare tax is a U.S. payroll tax supporting the Medicare health
        insurance program. It comprises the Hospital Insurance (HI) tax for
        hospital stays and skilled nursing care and the Medicare Part B tax for
        outpatient and physician services. Both employees and employers
        contribute 1.45%, totaling 2.9%, with an additional 0.9% for high-income
        earners. This tax ensures funding for essential healthcare services
        provided by Medicare for eligible individuals, particularly those aged
        65 and older. The program is administered by the Social Security
        Administration.
      </p>
      <h2 className="mt-5">Federal Income Tax</h2>
      <p>
        Federal income tax is a tax imposed by the United States government on
        the income of individuals and businesses. Administered by the Internal
        Revenue Service (IRS), it funds government operations. Individuals
        report income, deductions, and credits annually, with a progressive tax
        rate system. Businesses also report income and expenses. State
        governments may impose additional income taxes. It's essential for
        individuals and businesses to stay informed about tax laws or seek
        professional assistance for compliance.
      </p>
    </div>
  );
};

export default TaxInfo;
