import React, { useContext, useEffect, useState } from "react";
import PastResult from "../../components/Past_result";
import "./index.css";
import { sortObjectInDescendingOrder } from "../../functions/lotto-useful-functions";
import Disclaimer from "../Disclaimer";
import DrawingDate from "../drawingDate";
import { AppContext } from "../../context/app_context";

const Combo = () => {
  const { pastResults } = useContext(AppContext);
  const [nCombo, setNCombo] = useState(1);
  const [combo, setCombo] = useState([]);
  const [percent, setPercent] = useState(50);
  const [sumArray, setSumArray] = useState([]);
  const [drawingDay, setDrawingDay] = useState(0);

  const today = new Date().getDay();
  setInterval(() => {
    const currentDay = new Date().getDay();
    if (currentDay >= 0 && currentDay < 2) {
      setDrawingDay("Monday");
    } else if (currentDay >= 2 && currentDay < 5) {
      setDrawingDay("Wednesday");
    } else {
      setDrawingDay("Saturday");
    }
  }, 1000);

  // combo generate
  const generate = (e) => {
    const element = document.getElementById("nCombo");
    setNCombo(+element.value);
    let comboArray = [];

    while (comboArray.length < nCombo) {
      let tempCombo = [];
      while (tempCombo.length < 5) {
        const min = 1;
        const max = 69;

        const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;

        if (!tempCombo.includes(randomNumber)) {
          tempCombo.push(randomNumber);
        }
      }

      // get the sum of the combo
      let sum = tempCombo.reduce((sum, item) => {
        return sum + item;
      }, 0);

      if (sumArray.includes(sum)) {
        comboArray.push(tempCombo.sort((a, b) => a - b));
        setCombo(comboArray);
      }
    }
  };

  const changeRangeAndCombo = (e) => {
    const { className, id, value } = e.target;

    if (id === "nCombo") {
      setNCombo(+value);
    } else if (className.includes("percent")) {
      if (+value > 0 && +value <= 100) {
        setPercent(+value);
      }
    }
  };

  useEffect(() => {
    let dict = {};
    pastResults.forEach((element) => {
      // get the white numbers
      const winning_numberArray = element.winning_number.split(" ").slice(0, 5);
      // get the sum of the winning number
      let sum = winning_numberArray.reduce((sum, num) => {
        return (sum += +num);
      }, 0);
      // if (today >= 0 && today < 2) {
      //   if (String(element.date).includes("Mon")) {
      //     // add sum to dictionary and update the count
      //     if (dict[sum] === undefined) {
      //       dict[sum] = 1;
      //     } else {
      //       dict[sum] += 1;
      //     }
      //   }
      // } else if (today >= 2 && today < 5) {
      //   if (String(element.date).includes("Wed")) {
      //     // add sum to dictionary and update the count
      //     if (dict[sum] === undefined) {
      //       dict[sum] = 1;
      //     } else {
      //       dict[sum] += 1;
      //     }
      //   }
      // } else {
      //   if (String(element.date).includes("Sat")) {
      //     // add sum to dictionary and update the count
      //     if (dict[sum] === undefined) {
      //       dict[sum] = 1;
      //     } else {
      //       dict[sum] += 1;
      //     }
      //   }
      // }
      if (dict[sum] === undefined) {
        dict[sum] = 1;
      } else {
        dict[sum] += 1;
      }
    });

    //
    const sortedDict = sortObjectInDescendingOrder(dict);
    const length = sortedDict.length;
    const startInterval = 0;
    const endInterval = Math.round(length * (percent / 100));
    const selectedRange = sortedDict.slice(startInterval, endInterval);
    // console.log(sortedDict, length, dict, endInterval, selectedRange);
    const rangeArray = selectedRange.map((el) => {
      return +el[0];
    });

    setSumArray(rangeArray);
  }, [pastResults, percent, today]);

  // white combo
  const ComboJSK = combo.map((el, idx) => {
    const numsJSK = el.map((num, ind) => {
      return (
        <span className="white-ball" key={ind}>
          {num}
        </span>
      );
    });
    return (
      <div key={idx} className="flex gap-3 powerball m-2">
        {idx + 1 < 10 ? "0" + (idx + 1) : idx + 1} : {numsJSK}
      </div>
    );
  });
  return (
    <div className="mb-3 range-txt">
      <PastResult />
      <hr />
      <div>
        <DrawingDate />
        <hr />
        <div className="text-start mb-2">
          <strong>Top : </strong>
          <input
            type="number"
            className="range percent text-center mb-3"
            defaultValue={percent}
            onChange={changeRangeAndCombo}
            max={100}
            min={1}
          />
          %
          <br />
          <h3 className="text-start">
            Revealing Data-Driven Insight: Top {percent} Percentile Cumulative
            Sum Range
          </h3>
          <p className="text-start mb-4">
            After a thorough analysis of {pastResults.length} historical winning
            numbers, We are pleased to present a data-driven revelation: within
            the top {percent} percentile range for white balls cumulative sums,
            the most frequently occurring sum is {sumArray[0]}, while the least
            occurring sum is {sumArray[sumArray.length - 1]} for drawing
            happening on {drawingDay}.
          </p>
          <p>
            <strong>Number of Play:</strong>{" "}
            <input
              type="number"
              max={20}
              min={1}
              defaultValue={1}
              id="nCombo"
              className="text-center"
              onChange={changeRangeAndCombo}
            />
          </p>
        </div>
        <div className="text-start mb-3">
          <input
            type="submit"
            value={"Generate"}
            className="btn btn-primary"
            onClick={generate}
          />
        </div>
        <hr />
        <div className="flex combo gap-5">
          {combo.length > 0 ? (
            <div>
              <div className="mb-5 combo-num">
                <h3 className="combo-title">Combinations</h3>
                {ComboJSK}
              </div>
              <hr />
              <Disclaimer />
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default Combo;
