import React from "react";
import "./index.scss";
import ContactForm from "../Forms/Contact-form";

const Contact = () => {
  return (
    <div id="contact">
      <div>
        <ContactForm />
      </div>
    </div>
  );
};

export default Contact;
