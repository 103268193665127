import React from "react";
import "./index.scss";

const Footer = () => {
  return (
    <div id="footer" className="container-fluid">
      <p className="copyright">@ 2023 Bertrand Muhonga</p>
    </div>
  );
};

export default Footer;
