import React, { useContext } from "react";
import "./index.scss";
import moneyWatcher from "../../images/projects/money-watcher.png";
import lotteryCombinations from "../../images/projects/Lottery-combinations.png";
import incomeTax from "../../images/projects/income-tax.png";
import { Link } from "react-router-dom";
import { AppContext } from "../../context/app_context";

const Project = () => {
  const { setPage } = useContext(AppContext);
  const changePage = (e) => {
    const element = e.target;
    const { page } = element.dataset;
    setPage(page);
  };

  return (
    <div className="container-fluid" id="project">
      <div>
        <h2 className="section-title">Personal Projects</h2>
        <hr />
        <div className="overflow">
          <div className="projects-ctn">
            <Link
              to={"https://money-watcher.onrender.com/"}
              target="_blank"
              rel="noreferrer"
              className="link"
            >
              <div className="project">
                <h4>Money Watcher</h4>

                <div className="image">
                  <img src={moneyWatcher} alt="Money watcher" />
                </div>
              </div>
            </Link>
            <Link
              to={"/lucky-lotto"}
              className="link"
              data-page="project"
              onClick={changePage}
            >
              <div className="project">
                <h4 data-page="project">Lottery Combinations</h4>

                <div className="image">
                  <img
                    src={lotteryCombinations}
                    alt="Lottery combinations"
                    data-page="project"
                  />
                </div>
              </div>
            </Link>
            <Link
              to={"/income"}
              className="link"
              data-page="project"
              onClick={changePage}
            >
              <div className="project">
                <h4 data-page="project">Income Tax</h4>

                <div className="image">
                  <img src={incomeTax} alt="Income tax" data-page="project" />
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Project;
