import React from "react";
import "./index.scss";

const IncomeResult = (props) => {
  const {
    salary,
    monthly_salary,
    weekly_salary,
    daily_salary,

    afterTax,

    fedIncomeTax,
    fedTaxRate,
    monthly_fedIncomeTax,
    weekly_fedIncomeTax,
    daily_fedIncomeTax,

    ssTax,
    ssRate,
    monthly_ssTax,
    weekly_ssTax,
    daily_ssTax,

    medicareTax,
    medicareRate,
    monthly_medicareTax,
    weekly_medicareTax,
    daily_medicareTax,

    money,
  } = props;

  return (
    <div>
      <div className="result_div container-fluid">
        <table>
          <thead>
            <tr key="0">
              <th colSpan={2} className="tax_percent">
                Tax Percent
              </th>
            </tr>
          </thead>
          <tbody>
            <tr key="1">
              <td>Federal Income</td>
              <td className="value">{fedTaxRate}</td>
            </tr>

            <tr key="2">
              <td>Medicare</td>
              <td className="value">{(medicareRate * 100).toFixed(2)}</td>
            </tr>
            <tr key="3">
              <td>Social Security</td>
              <td className="value">{(ssRate * 100).toFixed(2)}</td>
            </tr>
          </tbody>
        </table>
        <div>
          <div className="result number">
            <h3 className="text-uppercase">Annual income after tax</h3>
            <p className="take_home d-flex gap-1 text-center">
              <strong>$</strong>
              <strong id="money">{money}</strong>
            </p>

            <div className="period">
              <details className="details">
                <summary>
                  <p>
                    <span>Est. Monthly Salary</span>
                    <strong className="value">$ {monthly_salary}</strong>
                  </p>
                </summary>
                <table>
                  <tbody>
                    <tr>
                      <td>Gross Salary</td>
                      <td className="value text-success">
                        $ {(salary / 12).toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <td>Federal income tax</td>
                      <td className="value">$ {monthly_fedIncomeTax}</td>
                    </tr>
                    <tr>
                      <td>Social security tax</td>
                      <td className="value">$ {monthly_ssTax}</td>
                    </tr>
                    <tr>
                      <td>Medicare tax</td>
                      <td className="value">$ {monthly_medicareTax}</td>
                    </tr>
                  </tbody>
                </table>
              </details>

              <details className="details">
                <summary>
                  <p>
                    <span>Est. Weekly Salary</span>
                    <strong className="value">$ {weekly_salary}</strong>
                  </p>
                </summary>
                <table>
                  <tbody>
                    <tr>
                      <td>Gross Salary</td>
                      <td className="value text-success">
                        $ {(salary / 52).toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <td>Federal income tax</td>
                      <td className="value">$ {weekly_fedIncomeTax}</td>
                    </tr>
                    <tr>
                      <td>Social security tax</td>
                      <td className="value">$ {weekly_ssTax}</td>
                    </tr>
                    <tr>
                      <td>Medicare tax</td>
                      <td className="value">$ {weekly_medicareTax}</td>
                    </tr>
                  </tbody>
                </table>
              </details>
              <details className="details">
                <summary>
                  <p>
                    <span>Est. Daily Salary</span>
                    <strong className="value">$ {daily_salary}</strong>
                  </p>
                </summary>
                <table>
                  <tbody>
                    <tr>
                      <td>Gross Salary</td>
                      <td className="value text-success">
                        $ {((salary / 2080) * 8).toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <td>Federal income tax</td>
                      <td className="value">$ {daily_fedIncomeTax}</td>
                    </tr>
                    <tr>
                      <td>Social security tax</td>
                      <td className="value">$ {daily_ssTax}</td>
                    </tr>
                    <tr>
                      <td>Medicare tax</td>
                      <td className="value">$ {daily_medicareTax}</td>
                    </tr>
                  </tbody>
                </table>
              </details>
            </div>
          </div>
          <div className="result text">
            <p>
              With an annual salary of ${salary}, the estimated annual take-home
              pay will be ${afterTax} after deducting ${fedIncomeTax} for
              federal income tax , ${ssTax} for Social Security tax, and $
              {medicareTax} for medicare tax.
            </p>
            <p>
              The net take-home pay is calculated by deducting only the amounts
              designated for the 2024 tax bracket, Social Security and Medicare
              taxes from the gross income.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IncomeResult;
