import React from "react";
import "./index.css";

const Interest = () => {
  return (
    <div id="interest" className="content-div">
      <div>
        <h3>Interests and Hobbies</h3>
        <ul>
          <li>Trading the Stock and Forex markets</li>
          <li>Science and technology.</li>

          <li>Reading, cooking and working out.</li>
        </ul>
      </div>
    </div>
  );
};

export default Interest;
