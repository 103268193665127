import React, { useContext } from "react";
import { AppContext } from "../../context/app_context";

import "./index.scss";

const Frequency = () => {
  const { pastResults } = useContext(AppContext);

  const today = new Date().getDay();

  // create a dict to store number frequency
  let dict = {};
  let redDict = {};
  pastResults.forEach((element) => {
    if (today >= 0 && today < 2) {
      if (String(element.date).includes("Mon")) {
        let whiteBalls = element.winning_number.split(" ").slice(0, 5);
        let redBall = element.winning_number.split(" ")[5];
        whiteBalls.forEach((num) => {
          if (num in dict) {
            dict[num] += 1;
          } else {
            dict[num] = 0;
          }
        });

        if (redBall in redDict) {
          redDict[redBall] += 1;
        } else {
          redDict[redBall] = 0;
        }
      }
    } else if (today >= 2 && today < 5) {
      if (String(element.date).includes("Wed")) {
        let whiteBalls = element.winning_number.split(" ").slice(0, 5);
        let redBall = element.winning_number.split(" ")[5];
        whiteBalls.forEach((num) => {
          if (num in dict) {
            dict[num] += 1;
          } else {
            dict[num] = 0;
          }
        });

        if (redBall in redDict) {
          redDict[redBall] += 1;
        } else {
          redDict[redBall] = 0;
        }
      }
    } else {
      if (String(element.date).includes("Sat")) {
        let whiteBalls = element.winning_number.split(" ").slice(0, 5);
        let redBall = element.winning_number.split(" ")[5];
        whiteBalls.forEach((num) => {
          if (num in dict) {
            dict[num] += 1;
          } else {
            dict[num] = 0;
          }
        });

        if (redBall in redDict) {
          redDict[redBall] += 1;
        } else {
          redDict[redBall] = 0;
        }
      }
    }
  });

  // Convert the object to an array of key-value pairs
  const keyValueArray = Object.entries(dict);
  const redKeyVal = Object.entries(redDict);

  // Sort the array in descending order based on values
  keyValueArray.sort((a, b) => b[1] - a[1]);
  redKeyVal.sort((a, b) => b[1] - a[1]);

  const whiteTop = keyValueArray.slice(0, 11);
  const redTop = redKeyVal.slice(0, 11);

  const whiteNumberFrequency = whiteTop.map((arr, idx) => {
    return (
      <p className="d-flex gap-3 mb-1" key={idx}>
        <span className="frequency-element">
          <span className="white-ball p-1">{arr[0]}</span>:
        </span>
        <span>{arr[1]}X</span>
      </p>
    );
  });
  const redNumberFrequency = redTop.map((arr, idx) => {
    return (
      <p className="d-flex gap-3 mb-1" key={idx}>
        <span className="frequency-element">
          <span className="red-ball ">{arr[0]}</span>:
        </span>
        <span>{arr[1]}X</span>
      </p>
    );
  });

  return (
    <div className="frequency-container">
      <p className="frequency-title">
        <span>Top 10 Number</span>
      </p>
      <div className="frequency-div-container">
        <div className="white">
          <div className="head">
            <p>White Balls</p>
          </div>
          <div className="frequency-white-container">
            {whiteNumberFrequency}
          </div>

          {/* <div className="page-button">
            <button
              type="button"
              className="fa fa-arrow-left"
              disabled={whitePage === 0 ? true : false}
              onClick={(e) =>
                setWhitePage(
                  e.target.className.includes("left")
                    ? whitePage - 1
                    : whitePage + 1
                )
              }
            ></button>

            <span className="page-number">{whitePage + 1}</span>
          </div> */}
        </div>

        <div className="red">
          <div className="head">
            <p>Red Balls</p>
          </div>
          <div className="frequency-red-container">{redNumberFrequency}</div>

          {/* <div className="page-button">
            <button
              type="button"
              className="fa fa-arrow-left"
              disabled={redPage === 0 ? true : false}
              onClick={(e) =>
                setRedPage(
                  e.target.className.includes("left")
                    ? redPage - 1
                    : redPage + 1
                )
              }
            ></button>

            <span className="page-number">{redPage + 1}</span>

            <button
              type="button"
              className="fa fa-arrow-right"
              disabled={redPage === sortedRedNumbers.length - 1 ? true : false}
              onClick={(e) =>
                setRedPage(
                  e.target.className.includes("left")
                    ? redPage - 1
                    : redPage + 1
                )
              }
            ></button>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Frequency;
