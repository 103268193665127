import React, { useEffect } from "react";
import "./index.scss";
import Combo from "../../components/Combo";
import Welcome from "../../components/Welcome";

const Lotto = () => {
  useEffect(() => {
    // Scroll to the top of the page
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="lotto container-fluid" id="lotto">
      <div className="lotto ">
        <Welcome />
        <Combo />
      </div>
    </div>
  );
};

export default Lotto;
