import React, { useEffect, useState } from "react";
import "./index.scss";
import TaxInfo from "../../components/tax_info";
import TaxCalculator from "../../components/tax_calculator";
import IncomeResult from "../../components/income_result";

const Income = () => {
  const [salary, setSalary] = useState(0);

  const [afterTax, setAfterTax] = useState(0);
  const [monthly_salary, setMonthlySalary] = useState(0);
  const [weekly_salary, setWeeklySalary] = useState(0);
  const [daily_salary, setDailySalary] = useState(0);

  const [medicareTax, setMedicareTax] = useState(0);
  const [monthly_medicareTax, setMonthlyMedicareTax] = useState(0);
  const [weekly_medicareTax, setWeeklyMedicareTax] = useState(0);
  const [daily_medicareTax, setDailyMedicareTax] = useState(0);
  const [medicareRate, setMedicareRate] = useState(0.0145);

  const [ssTax, setSsTax] = useState(0);
  const [monthly_ssTax, setMonthlySsTax] = useState(0);
  const [weekly_ssTax, setWeeklySsTax] = useState(0);
  const [daily_ssTax, setDailySsTax] = useState(0);
  const [ssRate, setSsRate] = useState(0.062);

  const [fedIncomeTax, setFedIncomeTax] = useState(0);
  const [monthly_fedIncomeTax, setMonthlyFedIncomeTax] = useState(0);
  const [weekly_fedIncomeTax, setWeeklyFedIncomeTax] = useState(0);
  const [daily_fedIncomeTax, setDailyFedIncomeTax] = useState(0);
  const [fedTaxRate, setFedTaxRate] = useState(0);

  const [money, setMoney] = useState(0);

  useEffect(() => {
    // Scroll to the top of the page
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const details = document.querySelectorAll(".details");
    details.forEach((el) => {
      el.open = false;
    });
  }, [money]);

  return (
    <div id="income" className="container-fluid pb-2 pt-2">
      <div className="contentDiv">
        <TaxInfo />
        <TaxCalculator
          setSalary={setSalary}
          setAfterTax={setAfterTax}
          setMonthlySalary={setMonthlySalary}
          setWeeklySalary={setWeeklySalary}
          setDailySalary={setDailySalary}
          setMonthlyFedIncomeTax={setMonthlyFedIncomeTax}
          setWeeklyFedIncomeTax={setWeeklyFedIncomeTax}
          setDailyFedIncomeTax={setDailyFedIncomeTax}
          setFedIncomeTax={setFedIncomeTax}
          setFedTaxRate={setFedTaxRate}
          setMonthlyMedicareTax={setMonthlyMedicareTax}
          setWeeklyMedicareTax={setWeeklyMedicareTax}
          setDailyMedicareTax={setDailyMedicareTax}
          setMedicareTax={setMedicareTax}
          setMedicareRate={setMedicareRate}
          setMonthlySsTax={setMonthlySsTax}
          setWeeklySsTax={setWeeklySsTax}
          setDailySsTax={setDailySsTax}
          setSsTax={setSsTax}
          setSsRate={setSsRate}
          setMoney={setMoney}
        />

        {afterTax > 0 ? (
          <IncomeResult
            monthly_salary={monthly_salary}
            weekly_salary={weekly_salary}
            daily_salary={daily_salary}
            salary={salary}
            afterTax={afterTax}
            fedIncomeTax={fedIncomeTax}
            monthly_fedIncomeTax={monthly_fedIncomeTax}
            weekly_fedIncomeTax={weekly_fedIncomeTax}
            daily_fedIncomeTax={daily_fedIncomeTax}
            fedTaxRate={fedTaxRate}
            medicareTax={medicareTax}
            monthly_medicareTax={monthly_medicareTax}
            weekly_medicareTax={weekly_medicareTax}
            daily_medicareTax={daily_medicareTax}
            medicareRate={medicareRate}
            ssTax={ssTax}
            monthly_ssTax={monthly_ssTax}
            weekly_ssTax={weekly_ssTax}
            daily_ssTax={daily_ssTax}
            ssRate={ssRate}
            money={money}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default Income;
