import React from "react";
import "./index.css";

const Disclaimer = () => {
  return (
    <div className="disclaimer text-start">
      <h6>Disclaimer</h6>
      The combinations generated by our platform are provided for informational
      and entertainment purposes only. While every effort has been made to
      ensure accuracy, we do not guarantee the correctness, completeness, or
      suitability of the combinations for any specific use, including but not
      limited to lottery play or any form of gambling.
      <br />
      <br />
      It is important to note that lottery outcomes are inherently unpredictable
      and subject to chance. Our combination generator is not intended to
      provide any guarantees or predictions regarding winning numbers or
      outcomes. Users should exercise their own judgment and discretion when
      utilizing the generated combinations for any purpose.
      <br />
      <br />
      We strongly encourage responsible gaming practices and advise users to
      adhere to the regulations and guidelines set forth by relevant authorities
      in their jurisdiction. Our platform shall not be held liable for any
      losses, damages, or actions resulting from the use of the generated
      combinations.
      <br />
      <br />
      By using our combination generator, you acknowledge that you have read,
      understood, and agreed to this disclaimer.
    </div>
  );
};

export default Disclaimer;
