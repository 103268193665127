import { useState, createContext } from "react";

export const AppContext = createContext();

// we take in props because we want access to the children
const AppContextProvider = (props) => {
  const [pastResults, setPastResults] = useState([]);
  const [drawingDay, setDrawingDay] = useState("All");
  const [drawingDays, setDrawingDays] = useState(["Mon", "Wed", "Sat"]);
  const [resultPage, setResultPage] = useState(0);
  const [page, setPage] = useState(0);

  return (
    <AppContext.Provider
      value={{
        pastResults,
        setPastResults,
        drawingDay,
        setDrawingDay,
        drawingDays,
        setDrawingDays,
        page,
        setPage,
        resultPage,
        setResultPage,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
