import React from "react";
import "./index.scss";
import Interest from "../Interests";

const About = () => {
  return (
    <div id="about" className="container-fluid pt-2 pb-2">
      <div>
        <h2 className="section-title">About me</h2>
        <hr />
        <div className="about-content">
          <div className="content-div">
            <p>
              Bertrand Muhonga is a passionate Software Engineer known for his
              strong work ethic and attention to detail. He excels at solving
              complex challenges and has a quick learning mindset.
              <br />
              <br />
              Bertrand's skill set includes data analytics, research, and
              proficiency in programming languages like JavaScript, Express,
              Java, and React.
              <br />
              <br />
              He specializes in MERN stack web development and also has
              experience with Spring Boot, Django frameworks and Power BI.
            </p>
          </div>
          <Interest />
        </div>
      </div>
    </div>
  );
};

export default About;
