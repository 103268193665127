import React from "react";
import "./index.css";
import { Link } from "react-router-dom";

const Nav = () => {
  const showNavbarCollapse = () => {
    const nav_collapse = document.querySelector(".navbar-collapse");
    if (nav_collapse.classList.contains("show")) {
      nav_collapse.classList.remove("show");
    }
  };
  return (
    <div className="nav-ctn">
      <nav className="navbar navbar-expand-lg ">
        <div className="container-fluid">
          <a className="navbar-brand" href="/">
            <span>B</span>
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarTogglerDemo02"
            aria-controls="navbarTogglerDemo02"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Link
                  className="nav-link"
                  aria-current="page"
                  to={"/"}
                  onClick={showNavbarCollapse}
                >
                  Home
                </Link>
              </li>

              <li className="nav-item dropdown">
                <span
                  className="nav-link dropdown-toggl"
                  // href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Project
                </span>
                <ul className="dropdown-menu">
                  <li>
                    <Link
                      className="dropdown-item"
                      to={"https://money-watcher.onrender.com/"}
                      target="_blank"
                      rel="noreferrer"
                      onClick={showNavbarCollapse}
                    >
                      Expense Tracker
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item"
                      to={"/income"}
                      onClick={showNavbarCollapse}
                    >
                      Income
                    </Link>
                  </li>

                  <li>
                    <Link
                      className="dropdown-item"
                      to={"/lucky-lotto"}
                      onClick={showNavbarCollapse}
                    >
                      Lucky Lotto
                    </Link>
                  </li>
                </ul>
              </li>

              {/* <li className="nav-item">
                <a className="nav-link" href="#education">
                  Education
                </a>
              </li> */}
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="#contact"
                  onClick={showNavbarCollapse}
                >
                  Contact
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Nav;
