import React from "react";
import "./index.scss";

import Skills from "../Skills";

const Education = () => {
  return (
    <div className="container-fluid pt-2 pb-2" id="education">
      <div className="content">
        <h2 className="section-title">Education</h2>
        <hr />
        <div className="education-content">
          <div>
            <div className="school">
              <div className="details">
                <p>
                  <span className="school-name">
                    <a
                      href="https://perscholas.org/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Per Scholas
                    </a>{" "}
                    | Dallas, Texas
                  </span>

                  <span className="inner-time-interval">
                    November 2022 &nbsp;<span className="bi-arrow-right"></span>
                    &nbsp; March 2023
                  </span>
                </p>
                <ul>
                  <li>Software Engineer bootcamp</li>
                </ul>
              </div>
            </div>
            <div className="school">
              <div className="details">
                <p>
                  <span className="school-name">
                    <a
                      href="https://www.marietta.edu/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Marietta College
                    </a>{" "}
                    | Marietta, Ohio
                  </span>

                  <span className="inner-time-interval">
                    August 2015 &nbsp;<span className="bi-arrow-right"></span>
                    &nbsp; December 2019
                  </span>
                </p>

                <ul>
                  <li>Bachelor of Science – Petroleum Engineering</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="certification">
            <h4>Certifications and Courses</h4>
            <ul>
              <li>
                CS50's Web Programming with Python and JavaScript(In progress)
              </li>
              <li>AWS Certified Solution Architect-Associate(In progress)</li>
              <li>
                <a
                  href="https://blob.sololearn.com/certificates/6908a78c-6e02-4a11-bb3b-420662f6d410.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  Java Intermediate
                </a>
              </li>
              <li>
                <a
                  href="https://freecodecamp.org/certification/fcc5696d929-d199-43ea-9c87-16d2fed266cb/responsive-web-design"
                  target="_blank"
                  rel="noreferrer"
                >
                  Responsive Web Design.
                </a>
              </li>
              <li>
                <a
                  href="https://www.coursera.org/account/accomplishments/specialization/certificate/U3DGCASLQKSZ"
                  target="_blank"
                  rel="noreferrer"
                >
                  Python for Everybody.
                </a>
              </li>
              <li>
                <a
                  href="https://courses.edx.org/certificates/c1dff859095d44698762e155b661ec64?_gl=1*1f0nyrn*_ga*MjA5MjYyNDU4MC4xNjgwMDc3OTM4*_ga_D3KS4KMDT0*MTY4NDk1MDgzOS4zNC4xLjE2ODQ5NTEzNzUuMC4wLjA."
                  target="_blank"
                  rel="noreferrer"
                >
                  Advanced Database Administration.
                </a>
              </li>
              <li>
                <a
                  href="https://courses.edx.org/certificates/876d99ef43e74b25bc2adc9a248b92de?_gl=1*1ycbqad*_ga*MjA5MjYyNDU4MC4xNjgwMDc3OTM4*_ga_D3KS4KMDT0*MTY4NDk1MDgzOS4zNC4xLjE2ODQ5NTE0NDMuMC4wLjA."
                  target="_blank"
                  rel="noreferrer"
                >
                  CS50's Introduction to Computer Science.
                </a>
              </li>
            </ul>
            <div>
              <h4 className="">Skills</h4>

              <div className="skill-icons">
                <Skills />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Education;
