import React from "react";
import "./index.scss";

const Experiences = () => {
  return (
    <div id="experience" className="container-fluid pt-2 pb-2">
      <div>
        <h2 className="section-title">Work Experiences</h2>
        <hr />
        <div className="experience-content">
          <div>
            November 2023 <span className="bi-arrow-right"></span> August 2024{" "}
            <br />
            Skillstorm - remote
            <h5 className="role">Data Engineer</h5>
            <ul>
              <li>
                As a Data Engineer Apprentice, I collected, processed, and
                managed data, designed and maintained databases and implemented
                processing workflows.
              </li>
              <li>
                I ensured data quality and collaborated with cross-functional
                teams.
              </li>
              <li>
                Dedicated to staying updated on industry trends, contributing to
                effective data analysis, and supporting informed decision-making
                within the organization.
              </li>
            </ul>
            <div className="container-fluid">
              <div className="tech-list gap-3">
                <span className="tech">Python</span>
                <span className="tech">Azure</span>
                <span className="tech">Power BI</span>
              </div>
            </div>
          </div>
          <div>
            April 2023 <span className="bi-arrow-right"></span> July 2024 <br />
            The Bridge Workforce - remote
            <h5 className="role">
              Software Engineer (<i>Volunteer</i>)
            </h5>
            <ul>
              <li>
                Engaged in a collaborative partnership with an esteemed client
                and a dedicated team of engineers, working diligently to
                architect, refine, and elevate an online education platform to
                the highest standards of quality and effectiveness.
              </li>
            </ul>
            <div className="container-fluid">
              <div className="tech-list gap-3">
                <span className="tech">MongoDB</span>
                <span className="tech">Express</span>
                <span className="tech">React</span>
                <span className="tech">Node</span>
              </div>
            </div>
          </div>

          <div className="content-div"></div>
        </div>
      </div>
    </div>
  );
};

export default Experiences;
