import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";

const ContactForm = () => {
  const [messageStatus, setMessageStatus] = useState("");
  const form = useRef();

  const sendEmail = (e) => {
    let message = form.current.children[9];

    e.preventDefault();
    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_PUBLIC_KEY
      )
      .then(
        (result) => {
          message.style.color = "green";
          setMessageStatus("Message was sent successfully!");
        },
        (error) => {
          message.style.color = "red";
          setMessageStatus("Something went wrong!");
        }
      );

    e.target.reset();
  };
  return (
    <form ref={form} onSubmit={sendEmail} className="container-fluid">
      <h4>Let's talk</h4>
      <label>
        Name<sup>*</sup>
      </label>
      <input type="text" name="user_name" required />
      <label>
        Email<sup>*</sup>
      </label>
      <input type="email" name="user_email" required />
      <label>Subject (Optional)</label>
      <input type="text" name="email_subject" />
      <label>
        Message<sup>*</sup>
      </label>
      <textarea name="message" required />
      <button type="submit">SEND</button>
      <label>{messageStatus}</label>
    </form>
  );
};

export default ContactForm;
