import React, { useEffect, useState } from "react";
import "./index.scss";

const DrawingDate = (props) => {
  const [drawingDay, setDrawingDay] = useState(undefined);

  const [nextDrawingDate, setNextDrawingDate] = useState();
  const [time, setTime] = useState({
    days: "",
    hours: "",
    minutes: "",
    seconds: "",
  });

  const getDateDifference = (startDate, endDate) => {
    const timeDifference = endDate - startDate;
    const seconds = Math.floor((timeDifference / 1000) % 60);
    const minutes = Math.floor((timeDifference / (1000 * 60)) % 60);
    const hours = Math.floor((timeDifference / (1000 * 60 * 60)) % 24);
    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    setTime((prevTime) => ({
      ...prevTime,
      days: days,
      hours: hours,
      minutes: minutes,
      seconds: seconds,
    }));
  };

  useEffect(() => {
    const timer = document.getElementById("time_countdown");
    const minutes = (time.days * 24 + time.hours) * 60 + time.minutes;
    if (minutes > 228) {
      timer.style.color = "green";
      timer.style.boxShadow = "inset 0 0 5px green";
    } else {
      timer.style.color = "red";
      timer.style.boxShadow = "inset 0 0 5px red";
    }
  }, [time.days, time.hours, time.minutes, time.seconds]);

  // ensure drawing date is not undefined
  if (drawingDay === undefined) {
    const currentDay = new Date().getDay();
    if (currentDay >= 0 && currentDay < 2) {
      setDrawingDay("Monday");
    } else if (currentDay >= 2 && currentDay < 5) {
      setDrawingDay("Wednesday");
    } else {
      setDrawingDay("Saturday");
    }
  }

  useEffect(() => {
    // Update the 'second' state every second
    const intervalId = setInterval(() => {
      const currentDay = new Date().getDay();
      if (currentDay >= 0 && currentDay < 2) {
        setDrawingDay("Monday");
      } else if (currentDay >= 2 && currentDay < 4) {
        setDrawingDay("Wednesday");
      } else {
        setDrawingDay("Saturday");
      }

      const getNextDrawingDate = () => {
        const daysOfWeek = [
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
        ];
        let nextDrawingDate = new Date();
        const today = new Date();

        // check today's day
        if (today.getDay() !== daysOfWeek.indexOf(drawingDay)) {
          // get the number of days until next drawing day
          const daysUntilDrawingDay =
            daysOfWeek.indexOf(drawingDay) - today.getDay();

          // update the date number
          nextDrawingDate.setDate(today.getDate() + daysUntilDrawingDay);

          // check if it is on the next month
          if (nextDrawingDate.getDate() < today.getDate()) {
            // update the month
            nextDrawingDate.setMonth(today.getMonth() + 1);
          }
        } else {
        }

        // set next drawing date time
        nextDrawingDate.setHours(22);
        nextDrawingDate.setMinutes(59);
        nextDrawingDate.setSeconds(0);

        getDateDifference(today, nextDrawingDate);

        // set update next drawing date
        setNextDrawingDate(nextDrawingDate.toLocaleDateString());
      };
      getNextDrawingDate();
    }, 1000);

    // Clear the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  });

  const days = String(time.days).padStart(2, "0");
  const hours = String(time.hours).padStart(2, "0");
  const minutes = String(time.minutes).padStart(2, "0");
  const seconds = String(time.seconds).padStart(2, "0");

  return (
    <div className="mb-4 next-drawing">
      <hr />
      <div>
        <div className="date_time">
          <h6 className="text-uppercase">Drawing date and time</h6>
          <p>
            <i>{drawingDay}</i>, {nextDrawingDate} @ 22:59 ET
          </p>
        </div>
        <div className="countdown_ctn">
          <h6 className="text-uppercase">Countdown</h6>
          <div className="countdown">
            <p id="time_countdown">
              {days > 0 ? <span className="time">{days}d,&nbsp;</span> : ""}
              {hours >= 0 && days !== 0 ? (
                <span className="time">{hours}h :&nbsp;</span>
              ) : (
                ""
              )}
              {minutes >= 0 && hours !== 0 ? (
                <span className="time">{minutes}m :&nbsp;</span>
              ) : (
                ""
              )}
              <span className="time">{seconds}s</span>
            </p>
          </div>
        </div>
      </div>
      <hr />
    </div>
  );
};

export default DrawingDate;
